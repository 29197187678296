import React from "react"
import Container from "../components/layout/textFormatingComponents/Container"
import { graphql } from "gatsby"
import GlobalStyles from "../components/layout/GlobalStyles"
import Header1 from "../components/layout/textFormatingComponents/Header1"
import Header2 from "../components/layout/textFormatingComponents/Header2"
import Header3 from "../components/layout/textFormatingComponents/Header3"
import Header4 from "../components/layout/textFormatingComponents/Header4"
import TextArea from "../components/layout/textFormatingComponents/TextArea"
import ResultCard from "../components/resultComponents/ResultCard"
import MetaDetails from "../components/seoComponents/MetaDetails"
import RowContainer from "../components/layout/textFormatingComponents/RowContainer"
import Button from "../components/layout/Button"
import ArticleImage from "../components/images/ArticleImage"
import { OutboundLink } from "gatsby-plugin-gtag"
import { AdserviceCompare } from "../components/CompareAdsense"

const FrontPage = ({ data }) => {
  const plansHook = data.allPlansJson.nodes
  const plans = plansHook.map(plan => {
    return (
      <ResultCard
        key={plan.id}
        img={plan.img}
        name={plan.name}
        price={plan.price}
        calls={plan.callPrice}
        texts={plan.textPrice}
        internet={plan.internet}
        speed={plan.internetSpeed}
        openFee={plan.openFee}
        url={plan.url}
        border={true}
        pageName={"moi"}
      />
    )
  })

  return (
    <GlobalStyles>
      <MetaDetails
        title="Moi liittymä kokemuksia - Katso hinnasto ja päätä itse!"
        desc="Lue täältä Moi liittymä kokemuksia. Katso kaikki Moi puhelinliittymät ja niiden hinnasto. Tutustu siis Moi Mobiiliin täältä ja nappaa paras tarjous!"
      />
      <Container>
        <Header1>Moi liittymä kokemuksia</Header1>
        <TextArea>
          Moi mobiili on uusin tulokas Suomen matkapuhelinmarkkinoilla. Se
          tarjoaa kuitenkin todella edullisia liittymiä ja niistä sinulle
          sopivimman voit valita alapuolelta.
        </TextArea>
        <Header3>Tutustu alapuolelta Moi liittymä tarjouksiin</Header3>
        <AdserviceCompare />
        <RowContainer title={"Tutustu myös muihin operaattoreihin:"}>
          <Button link={"/elisa/"} title={"Elisa"} />
          <Button link={"/telia/"} title={"Telia"} />
          <Button link={"/dna/"} title={"DNA"} />
        </RowContainer>
        <Header2>
          Moi Mobiili – se nopeampi, halvempi ja parempi puhelinoperaattori
        </Header2>
        <ArticleImage imgName="moietusivu.jpg" />
        <TextArea>
          Moi Mobiili on suomalainen puhelinoperaattori, joka on toiminut
          vuodesta 2020 asti DNA Oyj:n omistuksessa. Se tarjoaa edullisia ja
          nopeita puhelinliittymiä sekä kuluttajille että yritysasiakkaille
          täysin ilman sitoutumispakkoa tai määräaikaisuutta.
        </TextArea>
        <TextArea>
          Moi Mobiili -puhelinliittymää tilatessa tarjoutuu asiakkaalle
          tilaisuus räätälöidä juuri itselleen sopiva ratkaisu nopeasti ja
          helposti. Maksut puolestaan hoidetaan suoraan asiakkaan maksukortilta,
          joten puhelinlaskun eräpäiviäkään ei tarvitse enää muistaa.
        </TextArea>
        <Header3>Moi Mobiilin historia</Header3>
        <TextArea>
          Moi Mobiili perustettiin jo vuonna 2016 niin kutsutuksi
          virtuaalioperaattoriksi. Tämä tarkoittaa, että aloittaessaan ei Moilla
          ollut lainkaan omaa matkapuhelinverkkoa, vaan yritys vuokrasi sen
          DNA:lta. Moin perustajajoukkoon kuului yli 20 jäsentä ja rahoitus
          yritykselle järjestettiin yli 30 rahoittajan toimesta. Liikeideana oli
          tarjota kuluttajille alan asiakaslähtöisin puhelinoperaattori, joka
          toiminnallaan ei hae laajentumista suureksi toimijaksi, vaan pysyy
          pienenä ja asiakkaidensa rakastamana palveluntarjoajana. Moi
          otettiinkin lämmöllä vastaan kuluttajien puolelta, ja ensimmäisenä
          toimintavuonna liittymiä oltiin myyty jo kymmeniä tuhansia.
        </TextArea>
        <TextArea>
          Moi Mobiili kahdeksankertaisti liikevaihtonsa ensimmäisenä vuotenaan.
          Kesällä 2017 yritys sai poikkeusluvan Viestintävirastolta, jonka
          turvin operaattori pystyi alkamaan veloittamaan maksuja asiakkaidensa
          roaming-käytöstä ETA- ja EU -maissa. Syksyllä 2018 Moi Mobiili
          laajensi toimintaansa alkamalla tarjoamaan yritysasiakkailleen
          uudenlaisia palveluita. Yritysten oli nyt mahdollista tehdä Moin
          kanssa muun muassa jälkilaskutussopimuksia sekä aktivoida liittymiä
          entistä nopeammin. Kesästä 2018 lähtien yrityksestä käytiin useita
          myyntineuvotteluita sekä kotimaisten että ulkomaisten
          ostajaehdokkaiden kanssa. Lopulta Moi Mobiili myytiin
          kokonaisuudessaan DNA Oyj:lle tammikuussa 2020.
        </TextArea>
        <TextArea>
          <a href="/dna">DNA</a> päätti säilyttää pidetyn Moi-brändin ja jatkaa
          yrityksen tuotteiden myymistä entiseen tapaan. Tänä päivänä Moi
          Mobiili tarjoaa yhä palveluitaan sekä kuluttajille että yrityksille.
          Liiketoimintaa johdattaa ajatus olla helpompi ja halvempi vaihtoehto
          joka päivä. Yrityksen suurimpiin arvoihin kuuluu avoin ja reilu
          asiakassuhde sekä halu antaa asiakkaalle valta koskien
          henkilökohtaisia puhelinasioita. Moi Mobiili tarjoaa mukavan
          valikoiman helppoja puhelin- ja internetliittymä-ratkaisuja ilman
          sitovia määräaikaisuuksia ja lisäksi yritys antaa asiakkailleen
          seuraavat lupaukset:
          <ul>
            <li>Kaikki tehdään asiakkaan ehdoilla</li>
            <li>Arkiset asiat helpottuvat ja nopeutuvat Moin avulla</li>
            <li>Ihmisiä kuunnellaan</li>
            <li>Toimialaa pyritään uudistamaan</li>
            <li>Pidetään yllä positiivista anarkiaa</li>
            <li>Yritystoimintaa rehellisyyden ja avoimuuden kautta</li>
            <li>Rakkaus asiakkaita kohtaan</li>
          </ul>
        </TextArea>

        <TextArea>
          Moi Mobiilin perustajajoukoista löytyy useita alan ammattilaisia,
          joilla on vuosikausien kokemus matkapuhelinalalta. He ymmärtävät,
          kuinka turhauttavaa voi olla selailla loputonta liittymäviidakkoa ja
          koettaa selvittää, mikä puhelinliittymä olisi itselle sekä
          ominaisuuksiltaan että hinnaltaan paras – puhumattakaan tilanteista,
          joissa koko perheelle pitäisi hankkia puhelinliittymät. Tästä syystä
          helppojen ja selkeiden ratkaisujen tarjoaminen sekä kuluttajille että
          yrityksille on Moi Mobiilille lähtöajatus ja toimintaperiaate.
          Toimitusjohtaja Petri Lahtinen onkin sanonut: "Haluaisin tarjota
          asiakkaillemme hyvän diilin ja hymyn, aina."
        </TextArea>
        <Header2>Miksi valita Moi Mobiili liittymä?</Header2>
        <TextArea>
          Moi Mobiili liittymä tarjoaa innovatiivisia ratkaisuja jokapäiväisiin
          tarpeisiin. Suuret puhelinoperaattorit haluavat mielellään sitouttaa
          asiakkaitaan tarjoamalla heille useita vuosia kestäviä
          määräaikaistarjouksia ja kytkykauppoja, joista asiakas tuntee
          haluavansa pyristellä eroon jo yleensä viimeistään vuoden kuluessa.
          Moi Mobiilin asiakkaana ei sitä vastoin määräaikaisuuksista tai muista
          sitoumuksista tarvitse huolehtia, sillä liittymän voi irtisanoa
          milloin vain. Tällöin asiakkaan tulee vain olla yhteydessä yrityksen
          asiakaspalveluun, jonka jälkeen liittymän palvelut ja laskutus
          päättyvät noin 14 vuorokauden sisällä.
        </TextArea>
        <TextArea>
          Sitoutumattomuuden ja helppouden lisäksi Moin liittymät tuovat
          helpotusta arkeen, sillä ne toimivat DNA:n 4G LTE -verkossa. Verkko
          kattaa tällä hetkellä jo 99 % Suomesta, joten liittymää voi hyödyntää
          ympäri maan. Moi Mobiilin liittymällä on mahdollista päästä
          korkeimmillaan jopa 300 megan sekuntinopeuksiin, joten muun muassa
          suosikkisarjojen katseleminen, videoiden lataaminen sekä pelaaminen
          käyvät näppärästi ja mukavasti jopa usealla laitteella
          samanaikaisesti. Lisäksi Moilta löytyy valmius myös moderniin
          5G-verkkoon, jos saatavilla on tarkoitukseen sopiva laitekanta ja
          verkko.
        </TextArea>
        <TextArea>
          Moi Mobiilin asenne asiakkaitaan kohtaan on vailla vertaa. Sen huomaa
          jo yrityksen kattaville kotisivuille mentäessä, missä asiakas
          toivotetaan tervetulleeksi päivittäin palvelevan live chat -palvelun
          avulla. Kun eri liittymätyyppejä katselee, ilmestyy ruudulle
          puolestaan automatisoitu apulainen, joka tarvittaessa avustaa sopivan
          tuotteen löytymisessä. Sivustolta löytyy hyvin laaja usein kysytyt
          kysymykset -osio, josta on helppo tarkastaa tarvitsemansa informaatio
          ja Moin ystävälliseen asiakaspalveluun voi puolestaan live chatin
          lisäksi olla yhteydessä niin puhelimitse kuin sähköpostitsekin.
        </TextArea>
        <TextArea>
          Moi Mobiili liittymän yksi parhaista puolista on se, että sitä
          käyttäessä ei asiakas maksa turhasta. Eri liittymien maksut
          määräytyvät aina todellisen käytön mukaan ja jopa pakettihinnat
          sisältävät asiakasta suojelevia hintakattoja. Esimerkiksi kuuden euron
          liittymätyypin puheluiden maksimilasku voi olla korkeintaan kahdeksan
          euroa kuukaudessa ja rajattomien puolestaan neljä euroa kuukaudessa.
          Jos asiakas puolestaan soittaa ja lähettää viestejä alle
          liittymäpakettinsa hinnan, veloitetaan puhutuista minuuteista ja
          tekstiviesteistä tällöin 0,055 euroa kappaleelta tai minuutilta.
        </TextArea>
        <Header3>Millaisia liittymiä Moilla on?</Header3>
        <ArticleImage imgName="moiliittymat.jpg" />
        <TextArea>
          Moi Mobiililta löytyy yhteensä kolme erilaista liittymätyyppiä; 6
          euron simmi, Rajattoman Reilu sekä Rajaton Turbo. Kaikki liittymät on
          suunniteltu vastaamaan erilaisten käyttäjien tarpeisiin, jotta
          jokainen löytäisi itselleen sopivan ratkaisun. Laskutus tapahtuu
          kaikilla liittymillä samalla tavalla: kiinteä kuukausimaksu
          veloitetaan etukäteen asiakkaan maksukortilta ja käyttömaksut, kuten
          ylimääräiset puhelut, tekstiviestit tai datan käyttö, maksetaan
          puolestaan jälkikäteen. Kaikki tarjolla olevat liittymätyypit
          mahdollistavat lisäksi haluttaessa asiakkaan tunnistuksen sekä numeron
          siirtämisen liittymästä tai liittymätyypistä toiseen.
        </TextArea>
        <TextArea>
          Moi Mobiilin liittymien hinnoittelu hoituu niin kutsutulla
          poolihinnoittelulla. Tämä tarkoittaa, että yhden käyttäjän useamman
          SIM-kortin datakiintiö yhdistetään ja jaetaan. Esimerkiksi puhelin ja
          tabletti kerryttävät yhteistä datakiintiötä, jonka kehitystä voi
          seurata Moi-mobiilisovelluksesta. Palveluita voi laajentaa siirtymällä
          rajattoman datan käyttöön tai ostamalla lisää SIM-kortteja. Liittymiä
          valitessa onkin hyvä miettiä ensin omaa puhelin- ja internet
          -käyttäytymistään. Jos datan käyttö rajoittuu esimerkiksi sähköpostien
          lukemiseen ja viestien lähettelyyn Whatsappissa, voi neljä gigatavua
          sisältävä{" "}
          <OutboundLink href="/tilaa/moiperus" rel="nofollow">
            {" "}
            6 euron simmi -liittymätyyppi
          </OutboundLink>{" "}
          olla oikea valinta.
        </TextArea>
        <TextArea>
          <OutboundLink href="/tilaa/moirajattomanreilu" rel="nofollow">
            Rajattoman Reilu -liittymää
          </OutboundLink>{" "}
          kannattaa puolestaan miettiä silloin, jos tapana on katsella paljon
          videoita. Videotoistopalvelut vaativat tavallisesti melko paljon
          dataa, ja siksi rajattoman datan käytön mahdollistava liittymä on
          tällaiseen tarkoitukseen paras. Rajattoman Reilu -paketti mahdollistaa
          nettisurffailun hurjalla 100 Mbit/s -vauhdilla, joten jopa pitkien
          elokuvien katselu käy sen avulla vaivattomasti. Puhuminen ja
          tekstiviestien lähettely puolestaan kustantavat korkeintaan neljä
          euroa kuukaudessa, ja EU:n alueella liittymällä voi surffailla
          ilmaiseksi kymmenen gigan verran.
        </TextArea>
        <TextArea>
          <OutboundLink href="/tilaa/moirajatonturbo" rel="nofollow">
            Rajaton Turbo
          </OutboundLink>{" "}
          on Moi Mobiilin kaikkein massiivisin liittymäpaketti, joka on
          kehitelty erityisesti paljon surffaaville ja videoita katseleville
          asiakkaille. Rajaton Turbo on erinomainen vaihtoehto niin ikään
          mobiilireitittimeen, jolloin sen dataa voi jakaa muillekin laitteille.
          Liittymässä on nimittäin tarjolla jopa hurja 300 Mbit/s maksiminopeus,
          joten sen välityksellä voi katsella vaikka samanaikaisesti useaa
          elokuvaa eri laitteilla. Rajattoman Reilu -liittymän tapaan puheista
          ja tekstiviesteistä veloitetaan korkeintaan neljä euroa kuukaudessa ja
          EU:n alueella käytettävä data on kymmenen gigaa.
        </TextArea>
        <Header2>Tarjoukset ja palvelumaksut</Header2>
        <TextArea>
          Moi Mobiilin nettisivustolla ei tarjolla ole erityisiä kampanjoita ja
          tarjouksia, mutta ilmoittautumalla yrityksen sähköpostilistalle on
          voimassa olevat tarjoukset mahdollista vastaanottaa sähköpostin kautta
          aina kun ne ovat ajankohtaisia. Listalle ilmoittautuminen tapahtuu
          sivun alaosassa, johon asiakkaan tulee kirjoittaa ainoastaan oma
          sähköpostiosoitteensa. Moin tarjoamat liittymävaihtoehdot kuuluvat
          hinnoiltaan Suomen edullisimpiin puhelinliittymiin, joten vaikka
          erikoistarjouksia ei ole saatavilla, on tiedossa markkinoiden halvinta
          ja helpointa surffausta sekä puhumista kätevästi kiinteään
          kuukausihintaan ilman ylimääräisiä sitoumuksia.
        </TextArea>
        <TextArea>
          Moi Mobiilin edullisin liittymätyyppi on 6 euron simmi, joka kustantaa
          nimensä mukaisesti vain kuusi euroa kuukaudessa. Koska puhelut ja
          tekstiviestit veloitetaan Moilla erikseen, voi 6 euron simmin
          kuukausimaksu olla korkeintaan 14 euroa kuukaudessa. Rajattoman reilu
          -liittymän käyttö maksaa puolestaan 18 euroa, mutta korkeintaan lasku
          voi olla 22 €/kk ja suurimman Rajaton Turbo -paketin perushinta on 23
          euroa, mutta korkeintaan se voi kustantaa asiakkaalle 27 euroa
          kuukaudessa. Huomioitavaa on, että Moi perii joistakin toiminnoista
          palvelumaksuja seuraavanlaisesti:
          <ul>
            <li>MMS-viestit 0,30 €/kpl</li>
            <li>Vastaaja 1,50 €/kk</li>
            <li>Sim-korttien toimitus tai vaihto 5 €</li>
            <li>Maksumuistutus 5 €</li>
            <li>PUK-koodin kysely asiakaspalvelussa 10 €</li>
            <li>Liittymän tilapäinen sulku tai avaus asiakaspalvelussa 10 €</li>
            <li>Laskuerittely asiakaspalvelusta 10 €</li>
          </ul>
        </TextArea>
        <TextArea>
          Kaikkiin Moin liittymätyyppeihin sisältyy myös jonkin verran
          ulkomailla käytettävää dataa. 6 euron simmillä voi surffailla yhden
          gigatavun verran kuukaudessa ulkomailla. Rajattoman Reilu -paketti
          sisältää puolestaan kaksi gigatavua ulkomaandataa ja Rajaton Turbo
          -liittymän omistajat voivat selata nettiä ulkomailla jopa 10 gigatavun
          edestä kuukaudessa. Kaikkien liittymätyyppien ulkomaanpuheluiden
          hinnat ovat samat. Pohjoismaissa ja EU:n sisällä puheluista
          veloitetaan 0,055 €/minuutissa ja muualla Euroopassa puheluiden hinnat
          ovat 0,75 €/minuutilta. Tekstiviestit ulkomaille maksavat 0,055 euroa
          kappale ja MMS-viestit puolestaan 0,35 €/kpl.
        </TextArea>
        <TextArea>
          Yhdysvaltoihin ja Kanadaan soitettaessa kustannus on 0,25 euroa
          minuutissa, kun taas Kiinaan ja Japaniin menevistä puheluista peritään
          0,50 euron minuuttimaksu. Muualle Amerikkaan tai Aasiaan soitettaessa
          hinta on euron per minuutti ja Afrikan ja Oseanian puheluista
          veloitetaan puolestaan 1,25 - 1,50 euroa minuutilta. Moi Mobiilin
          hinnat eivät siis päätä huimaa ulkomaille soitettaessakaan, ja
          dataakin on tarjolla riittävästi. Yrityksen tavoitteena onkin tarjota
          asiakkailleen jatkuvasi edullisia ja käytännöllisiä ratkaisuja, joissa
          säilyy asiakkaan ehdoton päätäntävalta sekä vapaus.
        </TextArea>
        <Header4>Yrityspalvelut</Header4>
        <TextArea>
          Moi Mobiili on kunnostautunut myös yrityspalveluiden kohdalla ja
          tarjoaakin innovatiivisia ratkaisuja niin pienten kuin suurempienkin
          yritysten tarpeisiin. Moin yrityksille tarjoamien puhelinliittymien
          paras puoli on se, että kaikki liittymät on mahdollista yhdistää
          yhteen pooliin. Toisin sanoen liittymiä ei ole tarpeen räätälöidä
          yksilöllisesti, vaan ne kaikki käyttävät samaa dataa. Täten jokainen
          liittymänhaltija saa tarpeidensa mukaisen liittymäratkaisun eikä
          yritys päädy maksamaan turhasta. Oman yrityksen tarpeisiin vastaavan
          kokonaisuuden voi rakentaa itse Moi Mobiilin sivuilta löytyvän
          laskurin avulla.
        </TextArea>
        <TextArea>
          Oma yrityspaketti on helppo rakentaa laskurin avulla. Kun yrityksen
          liittymäkantaa alkaa räätälöimään, kysyy laskuri aluksi tarvittavien
          liittymien määrää. Liittymiä voi halutessaan tilata lähes rajattoman
          määrän sen mukaan, kuinka monta puhelinta tarvitsevaa työntekijää
          yrityksestä löytyy. Tämän jälkeen määritellään arvio tarvittavasta
          datan määrästä sekä puheminuuttien ja tekstiviestien määrästä.
          Datavaihtoehtoina on 5 Gt, 10 Gt tai rajaton data, ja puheminuuteiksi
          ja tekstiviestien määräksi voi asettaa joko 0, 30, 600 tai 1 200.
        </TextArea>
        <TextArea>
          Kun kaikki tarvittavat tiedot on syötetty laskuriin, näkyy paketille
          koituva kuukausittainen hinta sivun oikeassa reunassa. Lisäksi
          näkyvillä on erittely siitä, mitä kaikkea kyseessä olevaan pakettiin
          sisältyy. Kannattaa huomioida, että palvelumaksut yritysliittymille
          eroavat kuluttajahinnoista hieman. Liittymien käytön seuraaminen on
          helppoa Moi Mobiilin YritysMoi -tilinhallintapalvelun avulla, joka
          mahdollistaa kulujen seuraamisen ja palveluiden hallinnoimisen
          kaikkina vuorokaudenaikoina vuoden ympäri. Palvelussa koko yrityksen
          yhteistä poolia ja sen käyttöä on helppo seurata selkeiden taulukoiden
          avulla.
        </TextArea>
        <TextArea>
          Moi Mobiilin yrityspalvelut ovat saaneet kiitosta usealta taholta. Kun
          yritysten kaikkia puhelinliittymiä ja niiden käyttöä voi seurata
          yhdestä paikasta, on selvää, että säästöjä syntyy. YritysMoi
          mahdollistaa seurannan lisäksi liittymäpakettien pienentämisen tai
          suurentamisen itsenäisesti kulloisenkin tarpeen mukaan, joten
          yrittäjän ei tarvitse tuhlata kallisarvoista aikaansa
          puhelinoperaattorien palvelunumeroihin soitellakseen. Jos esimerkiksi
          yrityksen työntekijämäärä kasvaa, on uusien SIM-korttien tilaaminen
          palvelun kautta yksinkertaista sekä nopeaa, ja uudet liittymät on
          vaivatonta lisätä yrityksen liittymäpakettiin myös itse.
        </TextArea>
        <Header2>Yhteenveto</Header2>
        <TextArea>
          Moi Mobiili on puhelinoperaattori, joka tarjoaa helppoja ja nopeita
          liittymäratkaisuja sekä kuluttajille että yrityksille täysin ilman
          sitoumuksia tai määräaikaisuuksia. Kuluttajille tarjolla on kolme
          erilaista liittymäratkaisua, jotka eroavat toisistaan datan määrällä.
          Halvimmillaan liittymän saa jopa kuudella eurolla kuukaudessa.
        </TextArea>
        <TextArea>
          Moi-liittymien hinnoittelu noudattaa poolihinnoittelua, joka
          tarkoittaa, että laskussa yhdistetään usean eri sim-kortin
          datakiintiö. Ratkaisu sopiikin erityisesti usean hengen talouksille,
          sillä muun muassa käytetyn datan seuraaminen on Mun Moi -palvelussa
          yksinkertaista. Yritysten tarpeisiin Moi sopii erityisen hyvin, sillä
          YritysMoin avulla jopa 50 työntekijän puhelinliittymät ovat
          hallittavissa samasta paikasta. Moi Mobiili ei ole pelkästään yksi
          Suomen halvimmista ja nopeimmista puhelinoperaattoreista, vaan se
          pitää myös asiakkaansa aina tyytyväisenä.
        </TextArea>
      </Container>
    </GlobalStyles>
  )
}

export const query = graphql`
  query {
    allPlansJson(filter: { img: { eq: "moi.jpg" } }) {
      nodes {
        benefits
        callPrice
        id
        img
        internetSpeed
        internet
        name
        price
        textPrice
        type
        url
        openFee
      }
    }
  }
`

export default FrontPage
